import React, { useEffect, useState } from "react";

import WidgetBox from "../WidgetBox";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";
import { UseUserDetails } from "../../../hooks/auth";
import { GET_WIDGETS_LIST } from "../../../api/userWidgets";

const PersonalGoal = ({ widget = {}, reFetch, setReFetch, showPerformBox, showCharts = false, isSmallScreen = false }) => {
  const [randomNumber, setRandomNumber] = useState(0);
  const [personalGoalData, setPersonalGoalData] = useState("");
  const user = UseUserDetails();

  useEffect(() => {
    if (widget.multilineUserInput && widget.multilineUserInput.length) {
      setRandomNumber(
        Math.floor(Math.random() * widget.multilineUserInput.length),
      );
    }
  }, []);

  useEffect(() => {
    const fetchPersonalGoalData = async () => {
      try {
        const { data } = await GET_WIDGETS_LIST({ 
          key: "personal_goals",
          userId: user?._id 
        });
        
        if (data && data.length > 0) {
          setPersonalGoalData(data[0].userInput || "");
        }
      } catch (error) {
        console.error("Error fetching personal goal data:", error);
      }
    };

    if (user?._id) {
      fetchPersonalGoalData();
    }
  }, [user]);

  return showPerformBox ? (
    <PerformBoxFlexible
      widget={widget}
      title={widget.title}
      amount={
        widget.multilineUserInput && widget.multilineUserInput.length
          ? widget.multilineUserInput[randomNumber]
          : widget.userInput || ""
      }
      showGraph={false}
      showCharts={showCharts}
      isSmallScreen={isSmallScreen}
    />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={widget.title}
      widgetMetrics={personalGoalData || widget.userInput || ""}
      reFetch={reFetch}
      setReFetch={setReFetch}
    />
  );
};

export default PersonalGoal;
