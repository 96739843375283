import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { TableRow, TableCell, Dialog, DialogContent, DialogActions, Divider } from "@mui/material";

import { ADD_GROUP_MEMBER } from "../../api/group";
import { UseUserId } from "../../hooks/auth";
import { getCompanyUsers } from "../../redux/company/actions";
import SoftTypography from "../../components/SoftTypography";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React base styles
import RemovePlayer from "./RemovePlayer";
import ArchivePlayer from "./ArchivePlayer";
import BillingUpdate from "./BillingUpdate";
import UpdatePlayerRole from "./UpdatePlayerRole";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import { width } from "@mui/system";

const User = ({ user, workspaces, archiveUser }) => {
  const authUserId = UseUserId();
  const isLoggedInUser = useMemo(
    () => authUserId === user?.userId,
    [authUserId, user._id],
  );
  const [billingUpdateDialog, setBillingUpdateDialog] = React.useState(false);
  const dispatch = useDispatch();

  workspaces = workspaces.map((item) => ({
    ...item,
    value: item._id,
    label: item.name,
  }));
  const initialDept = workspaces.filter((item) => item._id === user.groupId);

  const { mutateAsync: addGroupMember } = ADD_GROUP_MEMBER();
  const [department, setDepartment] = React.useState(initialDept);
  const [selectedDepartment, setSelectedDepartment] = React.useState(initialDept);

  const openBillingUpdateDialog = () => setBillingUpdateDialog(true);
  const closeBillingUpdateDialog = () => setBillingUpdateDialog(false);

  const handleChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
  };

  const handleUpdate = async () => {
    if (selectedDepartment) {
      setDepartment(selectedDepartment);
      await addGroupMember({
        groupId: selectedDepartment.value,
        data: { userId: user.userId },
      });
      dispatch(getCompanyUsers());
    }
  };

  let status;

  if (user.isArchived) {
    status = "Archived";
  } else if (user.logins > 0) {
    status = "Active";
  } else if (!user.groupId) {
    status = "Inactive";
  } else {
    status = "Pending";
  }

  const [openWorkspaceModal, setOpenWorkspaceModal] = React.useState(false);
  

  return (
    <>
      {billingUpdateDialog && (
        <BillingUpdate
          billingUpdateDialog={billingUpdateDialog}
          closeBillingUpdateDialog={closeBillingUpdateDialog}
        />
      )}
      <Dialog
        open={openWorkspaceModal}
        onClose={() => setOpenWorkspaceModal(false)}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "8px",
            background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
            boxShadow: 24,
            padding: '1.75rem',
            maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
            width: "100%",
            overflow: "visible",
            backdropFilter: 'blur(14px)',
            height: "500px!important"
          },
        }}
      >
        <SoftBox display="flex" alignItems="center" sx={{ padding: "0 !important" }}>
          <SoftBox display="flex" alignItems="center">
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography
                component="h3"
                sx={{
                  fontSize: "18px",
                  color: "#FFF",
                  margin: 0,
                  padding: 0,
                  fontWeight: "500",
                }}
              >
                Edit Workspace
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <div className="ml-auto">
            <SoftButton
              variant="outlined"
              color="white"
              sx={{
                "& svg": { fontSize: "1rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
              onClick={() => setOpenWorkspaceModal(false)}
            >
              <CloseIcon />
            </SoftButton>
          </div>
        </SoftBox>
        <DialogContent sx={{ padding: "0 !important", mt: 5 }}>
            <SoftTypography sx={{ color: "#FFFFFF", fontSize: "14px", fontWeight: 600, mb: 1 }}>
              Change players workspace 
            </SoftTypography>
          <Select
            options={workspaces}
            value={selectedDepartment}
            onChange={handleChange}
            styles={{
              control: (base) => ({
                ...base,
                borderRadius: '4px',
                background: '#3D3D3F !important',
                border: '1px solid #3D3D3F',
                padding: '0.5rem 1rem !important',
                boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 4px rgba(8, 12, 19, 0.1) !important',
                marginBottom: '5rem',
              }),
              menu: (base) => ({
                ...base,
                background: '#3D3D3F',
                borderRadius: '4px',
                boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 4px rgba(8, 12, 19, 0.1)',
                height: '100%',
              }),
              option: (base, state) => ({
                ...base,
                background: state.isFocused ? '#4A4A4C' : '#3D3D3F',
                color: '#FFFFFF',
                '&:hover': {
                  background: '#4A4A4C'
                }
              }),
              singleValue: (base) => ({
                ...base,
                color: '#FFFFFF !important',
              }),
            }}
          />
          <SoftBox display="flex" justifyContent="space-between" sx={{ width: "98%", margin: "0 auto !important" }}>
            <SoftButton
              onClick={() => setOpenWorkspaceModal(false)}
              sx={{
                background: 'rgba(255, 255, 255, 0.1)',
                border: '1px solid rgba(233, 236, 239, 0.1)',
                borderRadius: '8px',
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 600,
                padding: '5px 40px!important',
                '&:hover': {
                  background: '#E9ECEF'
                }
              }}
            >
              Cancel
            </SoftButton>
            <SoftButton
              onClick={() => {
                handleUpdate();
                setOpenWorkspaceModal(false);
              }}
              sx={{
                background: '#FFFFFF',
                border: '1px solid #D0D5DD',
                borderRadius: '8px',
                color: '#344054',
                fontSize: '14px',
                fontWeight: 600,
                padding: '5px 40px!important',
                '&:hover': {
                  background: '#F9FAFB'
                }
              }}
            >
              Update
            </SoftButton>
          </SoftBox>
        </DialogContent>
      </Dialog>
      <TableRow>
        <TableCell>
          <SoftTypography
            sx={{ color: "#191D23", fontWeight: 500, fontSize: "18px" }}
          >
            {user.firstName} {user.lastName}
          </SoftTypography>
        </TableCell>
        <TableCell>
          {user.groupId ? (
            <SoftBox display="flex" alignItems="center">
            <SoftButton
              size="small"
              color="primary"
              sx={{ 
                width: "65%", 
                borderRadius: "4px",
                border: "1px solid #EAECF0",
                background: "#FFFFFF",
                color: "#191D23",
                boxShadow: "none !important",
                opacity: "1 !important",
                "&:hover": {
                  background: "#FFFFFF"
                },
              }}
            >
              {department[0]?.name || workspaces.find(w => w._id === user.groupId)?.name}
            </SoftButton>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setOpenWorkspaceModal(true)} style={{marginLeft: "0.25rem", cursor: "pointer"}}>
              <path d="M3.69333 13.0133C3.28667 13.0133 2.90667 12.8733 2.63333 12.6133C2.28667 12.2867 2.12 11.7933 2.18 11.26L2.42667 9.09999C2.47333 8.69333 2.72 8.15333 3.00667 7.85999L8.48 2.06666C9.84666 0.619992 11.2733 0.579992 12.72 1.94666C14.1667 3.31333 14.2067 4.73999 12.84 6.18666L7.36667 11.98C7.08667 12.28 6.56667 12.56 6.16 12.6267L4.01333 12.9933C3.9 13 3.8 13.0133 3.69333 13.0133ZM10.62 1.93999C10.1067 1.93999 9.66 2.25999 9.20667 2.73999L3.73333 8.53999C3.6 8.67999 3.44667 9.01333 3.42 9.20666L3.17333 11.3667C3.14667 11.5867 3.2 11.7667 3.32 11.88C3.44 11.9933 3.62 12.0333 3.84 12L5.98666 11.6333C6.18 11.6 6.5 11.4267 6.63333 11.2867L12.1067 5.49333C12.9333 4.61333 13.2333 3.79999 12.0267 2.66666C11.4933 2.15333 11.0333 1.93999 10.62 1.93999Z" fill="#98A2B3"/>
              <path d="M11.56 7.30001C11.5467 7.30001 11.5267 7.30001 11.5133 7.30001C9.43334 7.09334 7.76 5.51334 7.44 3.44668C7.4 3.17334 7.58667 2.92001 7.86 2.87334C8.13334 2.83334 8.38667 3.02001 8.43334 3.29334C8.68667 4.90668 9.99334 6.14668 11.62 6.30668C11.8933 6.33334 12.0933 6.58001 12.0667 6.85334C12.0333 7.10668 11.8133 7.30001 11.56 7.30001Z" fill="#98A2B3"/>
              <path d="M14 15.1667H2C1.72667 15.1667 1.5 14.94 1.5 14.6667C1.5 14.3933 1.72667 14.1667 2 14.1667H14C14.2733 14.1667 14.5 14.3933 14.5 14.6667C14.5 14.94 14.2733 15.1667 14 15.1667Z" fill="#98A2B3"/>
              </svg>
            </SoftBox>
          ) : null}
        </TableCell>
        <TableCell>
          <SoftTypography
            sx={{
              background: "#EFF9EE",
              color: "#119031",
              padding: "5px 15px",
              fontSize: "14px",
              fontWeight: 600,
              borderRadius: "4px",
              width: "50%",
              textAlign: "center",
            }}
          >
            {status}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <UpdatePlayerRole user={user} isLoggedInUser={isLoggedInUser} />
          {/* <Tooltip
            disableFocusListener
            title={`Click to set as ${user.isAdmin ? "Player" : "Admin"}`}
            placement="top"
          >
            <SoftButton
              size="small"
              variant={user.isAdmin ? "gradient" : "outlined"}
              color="primary"
              disabled={isLoggedInUser}
              sx={{ width: "8rem", borderRadius: "4px" }}
              onClick={() => handleAdmin(user.userId, user.isAdmin)}
            >
              {user.isAdmin ? (
                <>
                  Admin <PsychologyIcon fontSize="small" />
                </>
              ) : (
                <>
                  Player <SportsEsportsIcon fontSize="small" />
                </>
              )}
            </SoftButton>
          </Tooltip> */}
        </TableCell>
        <TableCell>
          <SoftBox display="flex" alignItems="center">  
            <ArchivePlayer
              isLoggedInUser={isLoggedInUser}
              archiveUser={archiveUser}
              user={user}
              archiveStatus={user.isArchived}
              openBillingUpdateDialog={openBillingUpdateDialog}
            />
            <RemovePlayer
              isLoggedInUser={isLoggedInUser}
              user={user}
              openBillingUpdateDialog={openBillingUpdateDialog}
            />
          </SoftBox>
        </TableCell>
      </TableRow>
    </>
  );
};
export default User;
