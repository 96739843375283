import React, { useEffect, useState } from "react";

import WidgetBox from "../WidgetBox";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";

import { UseUserDetails } from "../../../hooks/auth";
import { GET_WIDGETS_LIST } from "../../../api/userWidgets";

const AdminBulletin = ({
  widget = {},
  reFetch,
  setReFetch,
  showPerformBox,
  showCharts = false,
  isSmallScreen = false
}) => {
  const [randomNumber, setRandomNumber] = useState(0);
  const [adminBulletinData, setAdminBulletinData] = useState("");
  const user = UseUserDetails();

  useEffect(() => {
    if (widget.multilineUserInput && widget.multilineUserInput.length) {
      setRandomNumber(
        Math.floor(Math.random() * widget.multilineUserInput.length),
      );
    }
  }, []);

  useEffect(() => {
    const fetchAdminBulletinData = async () => {
      try {
        const { data } = await GET_WIDGETS_LIST({ 
          key: "admin_bulletin",
          userId: user?._id 
        });
        
        if (data && data.length > 0) {
          setAdminBulletinData(data[0].multilineUserInput || "");
        }
      } catch (error) {
        console.error("Error fetching admin bulletin data:", error);
      }
    };

    if (user?._id) {
      fetchAdminBulletinData();
    }
  }, [user]);

  return showPerformBox ? (
    <PerformBoxFlexible
      title={widget.title}
      widget={widget}
      amount={
        widget.multilineUserInput && widget.multilineUserInput.length
          ? widget.multilineUserInput[randomNumber]
          : widget.userInput || ""
      }
      showGraph={false}
      showCharts={showCharts}
      isSmallScreen={isSmallScreen}
    />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={widget.title}
      widgetMetrics={adminBulletinData || widget.userInput || ""}
      reFetch={reFetch}
      setReFetch={setReFetch}
    />
  );
};

export default AdminBulletin;
