import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UseIsAdmin } from "../../hooks/auth";

import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import SoftButton from "../SoftButton";
import { GET_PRICING } from "../../api/payment";

import GMB_LOGO from "../../img/main_logo.png";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./styles/upgradeModal.css";

const UpgradeModal = () => {
  const navigate = useNavigate();
  const isAdmin = UseIsAdmin();
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    GET_PRICING()
      .then(({ data }) => {
        const options = data.sort((a, b) => a.amount - b.amount);
        if (options?.length) {
          const featureList = (options[0].features ?? [])
            .filter(item => !item.includes('10 Members included')) 
            .splice(0, 8);
          setFeatures(featureList);
        }
      })
      .catch((err) => console.log("Stripe Get Prices Error", err));
  }, []);

  return (
    <Dialog
      open={true}
      PaperProps={{
        className: "upgrade-modal-bg",
        sx: { minWidth: { mini: "220px", lg: "1050px" } },
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(5, 25, 35, 0.70)",
          },
        },
      }}
    >
      <Box className="flex w-full h-full justify-between">
        <Box
          className="flex flex-col"
          sx={{ width: { mini: "100%", lg: "48%" } }}
        >
          <Box className="flex items-center gap-2 mb-[3.5rem]">
            <img
              src={GMB_LOGO}
              alt="Game My Biz"
              className="upgrade-top-logo"
            />
            <span className="upgrade-top-title">Game My Biz</span>
          </Box>
          <Box className="flex flex-col gap-4 max-w-[400px]">
            <p className="upgrade-title">Your Trial Period Has Ended</p>
            <p className="upgrade-text">
              Unfortunately, your trial has ended, and all features are now
              restricted.
            </p>
            {!isAdmin ? (
              <p className="upgrade-text">
                Reach out to your company admin to upgrade your company account.
                <br />
                <br />
                <sub>
                  <i>* Upgrade page is only available on desktop browsers.</i>
                </sub>
              </p>
            ) : (
              <p className="upgrade-text">
                Upgrade to a premium plan to unlock the full potential of
                Gamemybiz. Enjoy advanced features, enhanced capabilities, and
                continued support.
              </p>
            )}
          </Box>
        </Box>
        <Box
          width="50%"
          height="100%"
          sx={{ display: { mini: "none", lg: "block" } }}
        >
          <Box className="upgrade-features-box flex flex-col gap-8">
            <p className="upgrade-features-title">Upgrade Your plan</p>
            <Box className="flex flex-col gap-2">
              {features?.map((item) => (
                <Box
                  key={item}
                  className="flex justify-between items-center mt-2 text-[#E9ECEF] pr-[1rem]"
                >
                  <Box className="flex gap-3 text-inherit">
                    <Box
                      className="rounded-full flex items-center justify-center"
                      sx={{
                        paddingX: "5px",
                        maxHeight: "1.5rem",
                      }}
                    >
                      <CheckIcon
                        sx={{
                          fontSize: "0.75rem !important",
                        }}
                      />
                    </Box>
                    <span className="upgrade-features-list">{item}</span>
                  </Box>
                  <ErrorOutlineIcon sx={{ color: "inherit" }} />
                </Box>
              ))}
            </Box>
            <Box className="flex flex-col gap-2 items-center mt-[10%]">
              <SoftButton
                variant="contained"
                onClick={() => navigate("/upgrade")}
                sx={{ bgcolor: "#4B0082 !important", color: "#FFF" }}
                fullWidth
                disabled={!isAdmin}
              >
                Upgrade My Plan
              </SoftButton>
              <SoftButton
                variant="contained"
                onClick={() => navigate("/signout")}
                sx={{ bgcolor: "#19191F !important", color: "#FFF", border: "1px solid " }}
                fullWidth
              >
                Sign Out
              </SoftButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UpgradeModal;
