/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import InfiniteScroll from "react-infinite-scroll-component";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "./Breadcrumbs";
import NotificationItem from "../NotificationItem";
// import AdminViewToggle from "./AdminViewToggle";
import CustomizationSettings from "./CustomizationSettings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
  addNotification,
  deleteNotification,
  getUserNotification,
  updateAllNotificationStatus,
  updateNotificationReadStatus,
  updateNotificationStatus,
} from "../../redux/notifications/actions";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
  navbarIconsRow,
} from "./styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "../../context/softui";

import socket from "../../configs/socket";
import socketConstants from "../../constants/socket";
import { UseUserDetails } from "../../hooks/auth";
import SoftTypography from "../SoftTypography";
import SoftProfile from "../ProfilePictures/SoftProfile";

// isMini is a SoftUI sidenav flag for 'minimized'
// isSmallScreen is for checking screen size
function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [allNotification, setAllNotification] = useState(false);
  const navigate = useNavigate();
  const { SOCKET_EVENT } = socketConstants;

  const { notifications, unreadNotifications } = useSelector(
    (state) => state.notificationsReducer,
  );
  const { chats } = useSelector((state) => state.chatsReducer);

  const route = useLocation().pathname.split("/").slice(1);
  const { isAdmin } = UseUserDetails();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatchRedux = useDispatch();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const currentUser = UseUserDetails();

  const getNotifications = async () => {
    dispatchRedux(getUserNotification(currentUser._id));
  };

  const markAllReadTrigger = async () => {
    const payload = {
      userId: currentUser._id,
      is_read: true,
    };
    dispatchRedux(updateAllNotificationStatus(payload));
  };

  const markAllUnReadTrigger = async () => {
    const payload = {
      userId: currentUser._id,
      is_read: false,
    };
    dispatchRedux(updateNotificationReadStatus(payload));
  };

  const handleRead = async (id) => {
    dispatchRedux(updateNotificationStatus(id));
  };

  const handleDelete = async (id) => {
    dispatchRedux(deleteNotification(id));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENT.SEND_COMMENT_NOTI, (data) => {
      dispatchRedux(addNotification(data));
    });
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENT.SEND_SLOW_CLAP_NOTI, (data) => {
      dispatchRedux(addNotification(data));
    });
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENT.BADGE_COMPLETED, (data) => {
      dispatchRedux(addNotification(data));
    });
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENT.REFERRAL_SIGN_UP, (data) => {
      dispatchRedux(addNotification(data));
    });
  }, []);

  const switchHandler = () => {
    setAllNotification(!allNotification);
  };

  // Render the notifications menu
  const renderMenu = () => (
    <InfiniteScroll
      dataLength={3}
      // next={fetchNotifications}
      hasMore={true}
      // loader={isLoading && <Skeleton active />}
      scrollableTarget="scrollableDiv"
    >
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
        PaperProps={{
          style: {
            width: 450,
            padding: "15px",
          },
        }}
      >
        <div className="flex justify-between content-center mb-3">
          <SoftTypography variant="h4">Notification</SoftTypography>
          <div className="flex items-center">
            <span className="all-notification">All</span>
            <Switch
              onChange={switchHandler}
              checked={allNotification}
              inputProps={{ "aria-label": "controlled" }}
              className="mx-2"
            />
            <span className="unread-notification">Unread</span>
          </div>
        </div>

        {unreadNotifications.length !== 0 && allNotification
          ? unreadNotifications?.map((item, index) => (
              <NotificationItem
                /* eslint-disable */
                key={`${item.image}-${index}`}
                image={
                  <SoftProfile
                    src={item?.image}
                    name={item?.sender}
                    alt={item?.sender}
                    bgColor="dark"
                    size="md"
                    borderRadius="lg"
                  />
                }
                title={[
                  item?.action === "comment"
                    ? `commented on your job post by ${item?.senderName}`
                    : item?.action === "slow-clap"
                    ? `slow-clapped on your job post by ${item?.senderName}`
                    : item?.action === "referral"
                    ? `${item?.senderName} has signed up using your code`
                    : item?.action === "chat"
                    ? `New message from ${item?.senderName}`
                    : `Congratulations! You have completed ${item?.sender} badge.`,
                ]}
                date={item?.timeElapsed}
                onClick={() => {
                  handleCloseMenu();
                  dispatchRedux(updateNotificationStatus(item.notiId));
                  if (item.action === "badge") {
                    navigate("/settings/level-up", {
                      state: {
                        item,
                        showPopup: true,
                      },
                    });
                  } else if (item.action === "referral") {
                    navigate("/affiliates");
                  } else if (item.action === "chat") {
                    const elIndex = chats.findIndex(
                      (chat) => chat._id === item.chat,
                    );
                    navigate("/messages", {
                      state: {
                        chatToSelect: chats[elIndex],
                      },
                    });
                  } else {
                    navigate(`/feed/${item?.job}`);
                  }
                }}
                read={item?.is_read}
                notiId={item?.notiId}
                handleRead={handleRead}
                handleDelete={handleDelete}
              />
            ))
          : null}

        {notifications.length !== 0 && !allNotification
          ? notifications?.map((item, index) => (
              <NotificationItem
                key={`${item.image}-${index}`}
                image={
                  <SoftProfile
                    src={item?.image}
                    name={item?.sender}
                    alt={item?.sender}
                    bgColor="dark"
                    size="md"
                    borderRadius="lg"
                  />
                }
                title={[
                  item?.action === "comment"
                    ? `commented on your job post by ${item?.senderName}`
                    : item?.action === "slow-clap"
                    ? `slow-clapped on your job post by ${item?.senderName}`
                    : item?.action === "referral"
                    ? `${item?.senderName} has signed up using your code`
                    : item?.action === "chat"
                    ? `New message from ${item?.senderName}`
                    : item?.sender !== item?.receiver
                    ? `You received badge proof for ${item?.sender} badge`
                    : `Congratulations! You have completed ${item?.sender} badge.`,
                ]}
                date={item?.timeElapsed}
                onClick={() => {
                  handleCloseMenu();
                  dispatchRedux(updateNotificationStatus(item.notiId));
                  if (item.action === "badge") {
                    navigate("/settings/level-up", {
                      state: {
                        item,
                        showPopup: true,
                      },
                    });
                  } else if (item.action === "referral") {
                    navigate("/affiliates");
                  } else if (item.action === "chat") {
                    const elIndex = chats.findIndex(
                      (chat) => chat._id === item.chat,
                    );
                    navigate("/messages", {
                      state: {
                        chatToSelect: chats[elIndex],
                      },
                    });
                  } else {
                    navigate(`/feed/${item?.job}`);
                  }
                }}
                read={item?.is_read}
                allNoti={true}
                notiId={item?.notiId}
                handleRead={handleRead}
                handleDelete={handleDelete}
              />
            ))
          : null}

        {(!unreadNotifications.length && allNotification) ||
        (!notifications.length && !allNotification) ? (
          <div className="flex flex-col items-center mt-4">
            <NotificationsPausedIcon
              fontSize="24"
              style={{ fontSize: "44px" }}
            />
            <SoftTypography variant="h5" className="mt-2">
              You have no notifications
            </SoftTypography>
          </div>
        ) : null}

        {allNotification && unreadNotifications.length ? (
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              mb: 2,
            }}
          >
            <SoftButton color="dark" onClick={markAllReadTrigger}>
              Mark all as read
            </SoftButton>
          </SoftBox>
        ) : null}

        {!allNotification && notifications.length ? (
          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              mb: 2,
            }}
          >
            <SoftButton color="dark" onClick={markAllUnReadTrigger}>
              Make All As Unread
            </SoftButton>
          </SoftBox>
        ) : null}
      </Menu>
    </InfiniteScroll>
  );

  // <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
  return (
      <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, {
          transparentNavbar,
          absolute,
          light,
        }),
        padding: "0.5rem 1rem",
      })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* {!isSmallScreen && ( */}
        <SoftBox color="inherit" sx={(theme) => navbarRow(theme, { isMini })}>
          {/* {route[route.length - 1] === "home" ? null : ( */}
          <Breadcrumbs title={route[route.length - 1]} route={route} />
          {/* // )} */}
          {/* <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? <MenuOpenIcon />  : <MenuIcon />}
          </Icon> */}
        </SoftBox>
        {/* )} */}
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarIconsRow(theme, { isMini })}>
            {/* {isAdmin && !isSmallScreen && <AdminViewToggle light={light} />} */}
            <SoftBox
              color={"inherit"}
            >
              <IconButton
                color="inherit"
                sx={navbarIconButton}
                onClick={() => setOpenSettings(!openSettings)}
              >
                {(isSmallScreen)
                  ? <SettingsOutlinedIcon className={"inherit"} />
                  : <SettingsIcon className={"inherit"} />
                }
              </IconButton>
              <IconButton
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                id="scrollableDiv"
              >
                <Badge badgeContent={unreadNotifications.length} color="error">
                  {(isSmallScreen)
                    ? <NotificationsOutlinedIcon className={"inherit"} />
                    : <NotificationsIcon className={"inherit"} />
                  }
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon
                  className={"inherit"}
                >
                  {miniSidenav ? <MenuOpenIcon /> : <MenuIcon />}
                </Icon>
              </IconButton>
              {renderMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
      <CustomizationSettings
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
        dark={true}
      />
      </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
