import React, { useState, useEffect } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { UseIsAdmin } from "../../hooks/auth";
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  List,
  ListItemButton,
  ListItemText,
  Popover,
  ListItem,
  ListItemIcon,
  Divider,
} from "@mui/material";

// Imported Images and Icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GET_IMAGE } from "../../helpers/images";
import { ENABLE_HUB_ITEM } from "../../api/hub";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinkBg from "../../img/powerlinks_bg.jpg";
import AssignDialog from "./AssignDialog";

const PlugPlay = ({
  id,
  url,
  type,
  customImage,
  fallbackImageUrl,
  name,
  assignedTo,
  personValue,
  isOwner,
  onEdit,
  switchOn,
  onEnableChange,
  data,
}) => {
  const appTheme = useTheme();
  const [enabled, setEnabled] = useState(switchOn);
  const [pluginImage, setPluginImage] = useState(null);
  const matches = useMediaQuery(appTheme.breakpoints.up(640));
  const isAdmin = UseIsAdmin();

  const HUB_CONTROL_LABELS = {
    EDIT: "Edit Link",
    ASSIGN: "Assign",
  };

  const [openHubMenu, setOpenHubMenu] = useState(null);
  const handleOpen = (event) => {
    setOpenHubMenu(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpenHubMenu(null);
  };

  const handleLaunch = (e) => {
    if (
      e.target.innerText === undefined ||
      (e.target.innerText !== undefined &&
        !Object.values(HUB_CONTROL_LABELS).includes(e.target.innerText))
    ) {
      window.open(url);
    }
  };

  const openHubAppMenu = Boolean(openHubMenu);

  const handleEnable = (e) => {
    const isEnabled = e.target.checked;
    setEnabled(isEnabled);
    ENABLE_HUB_ITEM(id, isEnabled).then(() => {
      onEnableChange?.();
    });
  };

  useEffect(() => {
    if (customImage && customImage.type) {
      // is an image blob from new file for upload
      setPluginImage(URL.createObjectURL(customImage));
    } else {
      // can be sourced from either user-defined (customImage) or gmb-admin-defined (fallbackImg)
      // any of the two can either be an image URL or an S3 image key
      const image = customImage || fallbackImageUrl;
      if (image) {
        setPluginImage(GET_IMAGE(image));
      }
    }
  }, [customImage, fallbackImageUrl]);

  const maxLength = 20;
  const truncatedText = url.length > maxLength ? url.substring(0, maxLength) + '.....' : url;

  return (
    <SoftBox
      sx={{
        // display: "flex",
        // justifyContent: "space-between",
        borderRadius: "8px",
        border: "1px solid #EAECF0",
        boxShadow: " 0px 2px 3px 0px rgba(0, 0, 0, 0.05)",
        width: "100%",
        background: "#FCFCFD",
      }}
      // onClick={handleLaunch}
    >
      <SoftBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: { mini: "12px 12px 0px 12px", md: "24px 24px 0px 24px" },
          alignItems: "start",
        }}
      >
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SoftBox
            sx={{
              width: { mini: "36px", md: "56px" },
              height: { mini: "36px", md: "56px" }  ,
              background: "#fff",
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #E9ECEF",
              boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.05)",
              padding: "8px",
              marginBottom: "8px",
              cursor: "pointer"
            }}
            onClick={handleLaunch}
          >
            {pluginImage ? (
              <img src={pluginImage} alt="" className="w-full" />
            ) : (
              <SoftTypography variant="h3" sx={{ color: "primary.main", fontSize: { mini: "20px", lg: "27px" } }}>
                {name && name[0]}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox sx={{ position: "relative" }}>
            <SoftButton
              sx={{
                boxShadow: "none",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
                background: "transparent",
                "& svg": {
                  fill: "gray",
                },
                "&:hover": {
                  background: "transparent",
                },
                "&:focus:not(:hover)": {
                  background: "transparent",
                },
              }}
              onClick={handleOpen}
            >
              <MoreVertIcon sx={{ fontSize: "1.5rem !important" }} />
            </SoftButton>
            <Popover
              id="hubAppMenu"
              open={openHubAppMenu}
              onClose={handleClose}
              anchorEl={openHubMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  boxShadow: "none",
                  padding: "0rem",
                  background: "white",
                  border: "1px solid #00000066",
                },
              }}
            >
              <SoftBox>
                <List>
                  <ListItem>
                    <ListItemButton sx={{ gap: "4px" }} onClick={onEdit}>
                      <ListItemIcon sx={{ minWidth: "max-content" }} >
                        <EditOutlinedIcon sx={{ color: "black !important" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={HUB_CONTROL_LABELS.EDIT}
                        primaryTypographyProps={{
                          color: "black !important",
                          fontWeight: "medium",
                          variant: "caption",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </SoftBox>
            </Popover>
          </SoftBox>
        </SoftBox>
        <SoftTypography
          variant="h5"
          sx={{
            fontSize: { mini: "14px", md: "17px" },
            fontWeight: 500,
            color: "#000",
          }}
        >
          {name}
        </SoftTypography>
        <SoftButton
          color="primary"
          variant="text"
          sx={{
            textTransform: "none",
            color: "#6C757D",
            fontWeight: 600,
            fontSize: { mini: "10px", md: "14px" },
            fontFamily: "DM Sans",
            height: "min-content",
            width: "max-content",
            borderRadius: "4px",
            padding: "0px",
            "&:hover": {
              color: "#6C757D",
            },
          }}
          onClick={handleLaunch}
        >
          {truncatedText} <LaunchIcon sx={{ color: "#6C757D", marginLeft: "5px" }} />
        </SoftButton>
      </SoftBox>
      <Divider
        sx={{
          backgroundColor: "#6C757D",
          marginBottom: "0px",
        }}
      />
      <SoftBox sx={{ padding: "10px 0px" }}>
        <AssignDialog
          id={id}
          url={url}
          type={type}
          assignedTo={assignedTo}
          onEnableChange={onEnableChange}
        />
      </SoftBox>

      {/* <div
      className={
        !isAdmin
          ? "px-[0.75rem] pt-[0.75rem] pb-[0.75rem] xs:pt-[1rem] xs:pb-[1rem] lg:pt-[1.25rem] lg:pb-[1.25rem] self-center"
          : "px-[0.5rem] pt-[0.5rem] pb-[0.25rem] sm:px-[0.75rem] sm:pt-[0.75rem] sm:pb-[0rem] self-center"
      }
    >
      {isAdmin ? (
        <div className="flex justify-center mb-[0rem] md:mb-[0.15rem] ">
          <Switch
            size={matches ? "medium" : "small"}
            checked={enabled}
            onChange={handleEnable}
          />
        </div>
      ) : null}
    </div> */}
      {/* <div className="flex items-center justify-between w-full h-full max-h-[36px] bg-[#F7F7F7] rounded-bl-[0.75rem] rounded-br-[0.75rem] xs:rounded-bl-[1rem] xs:rounded-br-[1rem] px-[0.25rem] ">
      <div className="flex items-center gap-x-[0.15rem] text-[#929292] text-xs sm:text-sm ">
        <PersonOutlineOutlinedIcon className="!text-lg" /> {personValue}
      </div>
      {isOwner && (
        <div
          className="flex items-center gap-x-[0.15rem] text-[#929292] text-xs sm:text-sm cursor-pointer"
          onClick={onEdit}
        >
          <EditOutlinedIcon className="!text-lg" />
        </div>
      )}
    </div> */}
    </SoftBox>
  );
};

export default PlugPlay;
