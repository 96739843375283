import React, { useState } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import toast from "react-hot-toast";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { DELETE_COLLEAGUE } from "../../api/user";

const RemovePlayer = ({ isLoggedInUser, user, openBillingUpdateDialog }) => {
  const [removePlayer, setRemovePlayer] = useState(false);
  const [loading, setLoading] = useState(false);

  const openRemoveDialog = () => setRemovePlayer(true);
  const closeRemoveDialog = () => setRemovePlayer(false);

  const deleteUser = async () => {
    setLoading(true);
    try {
      await DELETE_COLLEAGUE(user.userId);
      setLoading(false);
      closeRemoveDialog();
      openBillingUpdateDialog();
    } catch (error) {
      console.log("Error deleteUser", error.response);
      toast.error("Unable to delete the user");
      setLoading(false);
    }
  };

  return (
    <>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={openRemoveDialog} style={{cursor: "pointer"}}>
        <path d="M14 4.82C13.9867 4.82 13.9667 4.82 13.9467 4.82C10.42 4.46667 6.9 4.33334 3.41334 4.68667L2.05334 4.82C1.77334 4.84667 1.52667 4.64667 1.5 4.36667C1.47334 4.08667 1.67334 3.84667 1.94667 3.82L3.30667 3.68667C6.85334 3.32667 10.4467 3.46667 14.0467 3.82C14.32 3.84667 14.52 4.09334 14.4933 4.36667C14.4733 4.62667 14.2533 4.82 14 4.82Z" fill="#667085"/>
        <path d="M5.66669 4.14663C5.64003 4.14663 5.61336 4.14663 5.58003 4.13996C5.31336 4.09329 5.12669 3.83329 5.17336 3.56663L5.32003 2.69329C5.42669 2.05329 5.57336 1.16663 7.12669 1.16663H8.87336C10.4334 1.16663 10.58 2.08663 10.68 2.69996L10.8267 3.56663C10.8734 3.83996 10.6867 4.09996 10.42 4.13996C10.1467 4.18663 9.88669 3.99996 9.84669 3.73329L9.70003 2.86663C9.60669 2.28663 9.58669 2.17329 8.88003 2.17329H7.13336C6.42669 2.17329 6.41336 2.26663 6.31336 2.85996L6.16003 3.72663C6.12003 3.97329 5.90669 4.14663 5.66669 4.14663Z" fill="#667085"/>
        <path d="M10.14 15.5H5.85997C3.53331 15.5 3.43997 14.2133 3.36664 13.1733L2.93331 6.45997C2.91331 6.18664 3.12664 5.94664 3.39997 5.92664C3.67997 5.9133 3.91331 6.11997 3.93331 6.3933L4.36664 13.1066C4.43997 14.12 4.46664 14.5 5.85997 14.5H10.14C11.54 14.5 11.5666 14.12 11.6333 13.1066L12.0666 6.3933C12.0866 6.11997 12.3266 5.9133 12.6 5.92664C12.8733 5.94664 13.0866 6.17997 13.0666 6.45997L12.6333 13.1733C12.56 14.2133 12.4666 15.5 10.14 15.5Z" fill="#667085"/>
        <path d="M9.10666 11.8333H6.88666C6.61332 11.8333 6.38666 11.6066 6.38666 11.3333C6.38666 11.06 6.61332 10.8333 6.88666 10.8333H9.10666C9.37999 10.8333 9.60666 11.06 9.60666 11.3333C9.60666 11.6066 9.37999 11.8333 9.10666 11.8333Z" fill="#667085"/>
        <path d="M9.66665 9.16663H6.33331C6.05998 9.16663 5.83331 8.93996 5.83331 8.66663C5.83331 8.39329 6.05998 8.16663 6.33331 8.16663H9.66665C9.93998 8.16663 10.1666 8.39329 10.1666 8.66663C10.1666 8.93996 9.93998 9.16663 9.66665 9.16663Z" fill="#667085"/>
      </svg>
      {removePlayer && (
        <Dialog
          open={removePlayer}
          onClose={closeRemoveDialog}
          sx={{
            "& .MuiPaper-root": {
              padding: { mini: "1rem", md: "1.5rem 1.7rem" },
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "700px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <SoftBox display="flex" justifyContent="flex-end">
            <SoftButton
              variant="outlined"
              color="white"
              onClick={closeRemoveDialog}
              sx={{
                "& svg": { fontSize: "1.25rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
            >
              <CloseRoundedIcon />
            </SoftButton>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb="60px"
          >
            <QuestionMarkIcon
              sx={{ fontSize: "100px !important" }}
              color="white"
            />
            <SoftTypography
              component="h4"
              sx={{
                fontWeight: 700,
                color: "#FFF",
                fontSize: 24,
                marginBottom: "20px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              Are you sure you want to remove {user.firstName} {user.lastName}?
            </SoftTypography>
            <SoftTypography
              component="p"
              sx={{
                fontWeight: 500,
                color: "#FFF",
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Confirming this Action will update your Subscription
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" justifyContent="space-between">
            <SoftButton
              color="white"
              variant="outlined"
              disabled={loading}
              onClick={closeRemoveDialog}
              sx={{
                textTransform: "none",
                borderRadius: "4px",
              }}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant="contained"
              color="primary"
              onClick={deleteUser}
              disabled={loading}
              sx={{
                mx: 2,
                textTransform: "none",
                borderRadius: "4px",
                color: "primary.contrastText",
              }}
            >
              Remove Player
              {loading && (
                <CircularProgress
                  color="inherit"
                  size="1rem"
                  className="ml-2"
                />
              )}
            </SoftButton>
          </SoftBox>
        </Dialog>
      )}
    </>
  );
};

export default RemovePlayer;
