import React, { useState } from "react";
import { Grid } from "@mui/material";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import PageHeader from "../../components/PageHeader";
import SoftInput from "../../components/SoftInput";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import WidgetsList from "./WidgetsList";

const Widgets = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <PageHeader
        headerText="Widget"
        subheaderText="Manage Your Widgets for efficient tracking"
      />

      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        {/* Page Content */}
        <Grid container alignItems="center" spacing={2}>
          <Grid item mini={12} lg={10}>
            <SoftBox>
              <SoftTypography
                component="h4"
                sx={{ color: "#495057", fontWeight: "600", fontSize: "24px" }}
              >
                Widget Studio
              </SoftTypography>
              <SoftTypography
                component="p"
                sx={{ fontSize: "14px", color: "#667085", fontWeight: "500" }}
                mb="20px"
              >
                Easily activate any widges of your choice to get better insights<br />
                to your activities
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item mini={12} lg={2}>
            <SoftBox display="flex" alignItems="flex-end" width="100%">
              <SoftInput
                className="bg-light rounded-lg"
                type="text"
                placeholder="Search for widgets"
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                  background: "linear-gradient(0deg, #f8f9fa, #f8f9fa), linear-gradient(0deg, #f8f9fa, #f8f9fa)",
                  border: "1px solid #F2F4F7",
                  borderRadius: '4px',
                  width: "100%",
                }}
                icon={{
                  component: <SearchSvg />,
                  direction: "left",
                }}
              />
            </SoftBox>
          </Grid>
        </Grid>

        <SoftBox
          sx={{
            backgroundColor: "#FFF",
            padding: "2rem",
            borderRadius: "12px",
            marginTop: "1rem",
          }}
        >
          <WidgetsList searchTerm={searchTerm} />
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default Widgets;
