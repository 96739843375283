import React, { useEffect, useState } from "react";
// import Intercom from '@intercom/messenger-js-sdk';

import useMediaQuery from "@mui/material/useMediaQuery";

import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import Gameboard from "../../components/gameboard";
import LeaderBoard from "../../components/LeaderBoard";
import YetiKpiBox from "../../components/YetiKpiBox";
import AddKpiBox from "../../components/YetiKpiBox/AddKpiBox";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import { useNavigate } from "react-router-dom";
import onBoardingImage from "../../img/onBoarding.jpg";
import CloseIcon from '@mui/icons-material/Close';
import { formatNumber } from "../../helpers/formatter";
import {UseSubscriptionLevel} from "../../hooks/company";

import {
  UseUserDetails,
  UseIsDemo,
  UseLoading,
  UseIsAdmin,
} from "../../hooks/auth";
import { UseMyKpis, UseGroupKpis, UseKpiLoading } from "../../hooks/kpi";
import LoadingScreen from "../../components/LoadingScreen";
import "./homepageStyles.css";
import { signOutUser } from "../../redux/action";
import { refreshKpis } from "../../redux/kpi/actions";
import { useDispatch } from "react-redux";
import { homePropmt, installApp } from "../../hooks/addToHome";
// import DemoOnboarding from "../../components/DemoOnboarding/DemoOnboarding";

// Icons
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { borderRadius, fontSize, fontWeight, lineHeight, textAlign } from "@mui/system";
import boxShadow from "../../assets/theme/functions/boxShadow";
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses, Modal, Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';

// Add this custom tooltip component
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 425,
    background: 'linear-gradient(180deg, #101828 0%, #39558E 100%)',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.25)',
    color: '#101828',
  },
});

// Add this custom tooltip content component
const CustomTooltipContent = React.forwardRef(({ closeTooltip }, ref) => (
  <SoftBox ref={ref} sx={{ p: 2, textAlign: 'left !important' }}>
    <SoftTypography variant="h6" component="h3" sx={{ mb: 1, fontWeight: 'bold', color: "#FFFFFF" }}>
      King of the mountain
    </SoftTypography>
    <SoftTypography variant="body2" sx={{color: "#FFFFFF"}}>
      Navigate seamlessly with our Sidebar. Access key sections like Players, Bad guy, Hub, and more.
    </SoftTypography>
    <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2rem" }}>
      <SoftTypography variant="body2" sx={{color: "#FFFFFF"}}>
        3/3
      </SoftTypography>
      <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <SoftTypography variant="body2" sx={{color: "#FFFFFF", marginRight: "0.5rem", cursor: "pointer"}} onClick={closeTooltip}>
          End Tutorial
        </SoftTypography>
        <SoftButton
          variant="outlined"
          onClick={closeTooltip}
          sx={{
            background: "#FFFFFF",
            borderRadius: "4px",
            border: "1px solid #EAECF0",
            color: "#2152FF !important",
            textTransform: "capitalize !important",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
        >
          Got it
        </SoftButton>
      </SoftBox>
    </SoftBox>
  </SoftBox>
));

// Add this new custom tooltip content component
const CustomTooltipContent2 = React.forwardRef(({ closeTooltip, onNext }, ref) => (
  <SoftBox ref={ref} sx={{ p: 2, textAlign: 'left !important' }}>
    <SoftTypography variant="h6" component="h3" sx={{ mb: 1, fontWeight: 'bold', color: "#FFFFFF" }}>
      Team Performance VS. The Yeti
    </SoftTypography>
    <SoftTypography variant="body2" sx={{color: "#FFFFFF"}}>
      Widgets provide at-a-glance insights! Customize your dashboard with Widgets to monitor essential metrics and achievements
    </SoftTypography>
    <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2rem" }}>
      <SoftTypography variant="body2" sx={{color: "#FFFFFF"}}>
        2/3
      </SoftTypography>
      <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <SoftTypography variant="body2" sx={{color: "#FFFFFF", marginRight: "0.5rem", cursor: "pointer"}} onClick={closeTooltip}>
          Skip Tutorial
        </SoftTypography>
        <SoftButton
          variant="outlined"
          sx={{
            background: "#FFFFFF",
            borderRadius: "4px",
            border: "1px solid #EAECF0",
            color: "#2152FF !important",
            textTransform: "capitalize !important",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          onClick={onNext}
        >
          Next
        </SoftButton>
      </SoftBox>
    </SoftBox>
  </SoftBox>
));

function Homepage() {

  const [openDailyTargetDialog, setOpenDailyTargetDialog] = useState(false);

  const isLoading = UseLoading();
  const isAdmin = UseIsAdmin();
  const areKpisLoading = UseKpiLoading();
  const { _id: userId, onboardingStatus, isAdmin: isRealAdmin, email: userEmail, firstName: userfirstName, lastName: userlastName, logins } = UseUserDetails();
  const isDemo = UseIsDemo();
  const groupKpis = UseGroupKpis();
  const userKpis = UseMyKpis();
  const [kpis, setKpis] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSubscribed = UseSubscriptionLevel();
  const [showDemo, setShowDemo] = useState(
    isDemo && onboardingStatus !== "completed",
  );

  // Intercom({
  //   app_id: 'xpad1tsg',
  //   name: `${userfirstName} ${userlastName}`,
  //   email: userEmail
  // })

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [isVisible, setIsVisible] = useState(true);
  const handleHide = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (!isLoading) {
      if (!userId) {
        dispatch(signOutUser());
        document.location.href = "/";
      }
    }
  }, [isLoading, areKpisLoading, userId, dispatch]);

  useEffect(() => {
    const refreshScores = () => {
      if (userId) {
        dispatch(refreshKpis());
      }
    };

    // Refresh leaderboard scores every 10 minutes
    const refreshInteravlId = setInterval(refreshScores, 5 * 60 * 1000);
    refreshScores();

    return () => clearInterval(refreshInteravlId);
  }, [userId, dispatch]);

  useEffect(() => {
    if (groupKpis) {
      const kpiSummary = groupKpis?.map((kpiItem) => ({
        ...kpiItem,
        amount: userKpis?.month[kpiItem._id]?.amt ?? 0,
        points: userKpis?.month[kpiItem._id]?.pts ?? 0,
        target: kpiItem.target,
      }));
      setKpis(kpiSummary);
    }
  }, [groupKpis, userKpis]);

  useEffect(() => {
    const targetNode = document.getElementById("root");
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(() => {
      if (document.getElementById("ConfirmationModelSuccessBtn")) {
        installApp();
        observer.disconnect();
      } else if (document.getElementById("addToHomeClick")) {
        homePropmt();
      }
    });
    observer.observe(targetNode, config);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [triggerTooltip, setTriggerTooltip] = useState(false);

  useEffect(() => {
    if (logins === 0 && isSubscribed > 0) {
      setOpenModal(true);
    }
  }, [logins, isSubscribed]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setTriggerTooltip(true);
  };

  const handleCloseModal2 = () => {
    setOpenModal(false);
  };
  
  const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    background: "#FFF !important"
  };

  const [openGameboardTooltip, setOpenGameboardTooltip] = useState(false);
  const [openYetiTooltip, setOpenYetiTooltip] = useState(false);

  const handleNextTooltip2 = () => {
    setOpenYetiTooltip(false);
    setOpenGameboardTooltip(true);
  };

  const handleNextTooltip = () => {
    setOpenYetiTooltip(true);
  };

  const closeGameboardTooltip = () => {
    setOpenGameboardTooltip(false);
  };

  const closeYetiTooltip = () => {
    setOpenYetiTooltip(false);
  };

  return (
    <SoftBox sx={{ positon: "relative", marginTop: "2rem" }}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <SoftBox>
          <Box>
          {isVisible && <SoftBox className={alert} sx={{padding: { mini: "0.5rem", xxs: "1.5rem 1.5rem 0rem 1.5rem" }}}>
              <Grid container sx={{padding: "20px", backgroundColor: "#ffffff", alignItems: "center", display: isSmallScreen ? "none" : "flex", borderRadius: "8px" }}>
                <Grid item mini={2}>
                    <SoftTypography sx={{ color: "#000000", fontSize: { mini: "18px", md: "16px" }, lineHeight: "24px", fontWeight: "500" }} variant="h3">
                      Get started with Game My Biz
                    </SoftTypography>
                </Grid>
                <Grid item mini={8}>
                  <div style={{ display: 'flex', }}>
                    <SoftButton color="primary" sx={{ borderRadius: "4px", fontSize: { mini: "14px", md: "13.5px" }, marginRight: ".5rem", textTransform: "capitalize" }} onClick={() => navigate("/settings/players")}>
                      <svg style={{marginRight: ".5rem"}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="8" cy="8" r="7.66667" fill="#FCFCFD" stroke="#98A2B3" /> <path d="M7.33467 11V5.58667L6.28 5.82933V5.10133L7.72667 4.46667H8.36133V11H7.33467Z" fill="#667085"/> </svg>
                      Invite Players
                    </SoftButton>
                    <SoftButton color="primary" sx={{ borderRadius: "4px", fontSize: { mini: "14px", md: "13.5px" }, marginRight: ".5rem", textTransform: "capitalize" }} onClick={() => navigate("/gameboards/gameboard-library")}>
                      <svg style={{marginRight: ".5rem"}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="7.66667" fill="#FCFCFD" stroke="#98A2B3" />
                        <path d="M5.532 11V10.2907C5.95511 9.95467 6.35956 9.61556 6.74533 9.27333C7.13111 8.93111 7.47333 8.59511 7.772 8.26533C8.07689 7.92933 8.31644 7.60267 8.49067 7.28533C8.66489 6.96178 8.752 6.65067 8.752 6.352C8.752 6.15289 8.71778 5.96622 8.64933 5.792C8.58089 5.61156 8.46889 5.46844 8.31333 5.36267C8.15778 5.25067 7.94933 5.19467 7.688 5.19467C7.296 5.19467 7.00044 5.31911 6.80133 5.568C6.60844 5.81689 6.51511 6.11555 6.52133 6.464H5.56C5.56622 6.00356 5.66267 5.61778 5.84933 5.30667C6.04222 4.99555 6.29733 4.75911 6.61467 4.59733C6.93822 4.43555 7.30222 4.35467 7.70667 4.35467C8.32267 4.35467 8.81422 4.52889 9.18133 4.87733C9.55467 5.21956 9.74133 5.69867 9.74133 6.31467C9.74133 6.68178 9.65111 7.04578 9.47067 7.40667C9.29645 7.76756 9.06622 8.116 8.78 8.452C8.5 8.78178 8.19822 9.09289 7.87467 9.38533C7.55111 9.67778 7.24311 9.94222 6.95067 10.1787H9.93733V11H5.532Z" fill="#667085"/>
                      </svg>
                      Customize Gameboard
                    </SoftButton>
                    <SoftButton color="primary" sx={{ borderRadius: "4px", fontSize: { mini: "14px", md: "13.5px" }, textTransform: "capitalize" }} onClick={() => navigate("/gameboards/workspace-kpi")}>
                      <svg style={{marginRight: ".5rem"}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="7.66667" fill="#FCFCFD" stroke="#98A2B3" />
                        <path d="M7.75333 11.112C7.33022 11.112 6.94444 11.0373 6.596 10.888C6.25378 10.7324 5.98 10.4991 5.77467 10.188C5.56933 9.87689 5.46044 9.488 5.448 9.02133H6.428C6.44044 9.36978 6.55867 9.66533 6.78267 9.908C7.01289 10.1444 7.33644 10.2627 7.75333 10.2627C8.15156 10.2627 8.45644 10.1538 8.668 9.936C8.87956 9.712 8.98533 9.43511 8.98533 9.10533C8.98533 8.70711 8.84222 8.41156 8.556 8.21867C8.276 8.02578 7.93067 7.92933 7.52 7.92933H7.03467V7.108H7.52933C7.89644 7.108 8.192 7.02089 8.416 6.84667C8.64622 6.67244 8.76133 6.42667 8.76133 6.10933C8.76133 5.84178 8.67111 5.624 8.49067 5.456C8.31644 5.288 8.06756 5.204 7.744 5.204C7.408 5.204 7.14356 5.30355 6.95067 5.50267C6.75778 5.69555 6.652 5.93822 6.63333 6.23067H5.65333C5.66578 5.85111 5.75911 5.52133 5.93333 5.24133C6.11378 4.96133 6.35956 4.74355 6.67067 4.588C6.98178 4.43244 7.33956 4.35467 7.744 4.35467C8.17956 4.35467 8.54356 4.43244 8.836 4.588C9.13467 4.73733 9.35867 4.94267 9.508 5.204C9.66356 5.45911 9.74133 5.73911 9.74133 6.044C9.74133 6.39244 9.64489 6.69733 9.452 6.95867C9.25911 7.22 9.00089 7.39422 8.67733 7.48133C9.05067 7.56222 9.35867 7.74578 9.60133 8.032C9.844 8.312 9.96533 8.676 9.96533 9.124C9.96533 9.48489 9.88133 9.81778 9.71333 10.1227C9.54533 10.4213 9.29644 10.6609 8.96667 10.8413C8.63689 11.0218 8.23244 11.112 7.75333 11.112Z" fill="#667085"/>
                      </svg>
                      Add metrics
                    </SoftButton>
                    </div>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} mini={2}>
                  <SoftButton onClick={() => setOpenModal(true)} sx={{ borderRadius: "4px", fontSize: { mini: "14px", md: "13.5px" }, textTransform: "capitalize", marginRight: "24px" }}>
                    Interactive Tour
                  </SoftButton>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor: "pointer"}} onClick={handleHide}>
                    <path d="M1.26668 9.66683L0.333344 8.7335L4.06668 5.00016L0.333344 1.26683L1.26668 0.333496L5.00001 4.06683L8.73334 0.333496L9.66668 1.26683L5.93334 5.00016L9.66668 8.7335L8.73334 9.66683L5.00001 5.9335L1.26668 9.66683Z" fill="#000"/>
                  </svg>
                </Grid>
              </Grid>
            </SoftBox>}
            <Hero triggerTooltip={triggerTooltip} onNextTooltip={handleNextTooltip} />
            <Grid
              container
              spacing={4}
              sx={{ padding: { xxs: "1.5rem" },paddingTop: { md: "10px" } }}
            >
              {kpis.length ? (
                <>
                  <Grid item mini={12} md={12} lg={6}>
                    <CustomWidthTooltip 
                      title={<CustomTooltipContent closeTooltip={closeGameboardTooltip} />}
                      placement="right"
                      arrow
                      open={openGameboardTooltip}
                    >
                      <div>
                        <Gameboard kpis={kpis} />
                      </div>
                    </CustomWidthTooltip>
                  </Grid>
                  <Grid item mini={12} md={12} lg={6}>
                    <CustomWidthTooltip 
                      title={<CustomTooltipContent2 closeTooltip={closeYetiTooltip} onNext={handleNextTooltip2} />}
                      placement="left"
                      arrow
                      open={openYetiTooltip}
                    >
                      <SoftBox
                        sx={{
                          height: { mini: "835px", xxs: "875px", md: "100%" },
                          padding: { mini: "0px 24px 0px 24px", xxs: "unset" },
                          ...(isSmallScreen && ({ height: "unset", minHeight: "100px !important" }))
                        }}
                      >
                        <SoftBox
                          sx={{
                            background: "#fff",
                            paddingTop: { mini: "16px", sm: "1rem" },
                            paddingBottom: "2rem",
                            overflowX: "hidden",
                            borderRadius: "12px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            maxHeight: { mini: "960px", xxs: "1000px", md: "850px" },
                            ...(isSmallScreen && ({ maxHeight: "unset", minHeight: "100px" }))
                          }}
                        >
                          <SoftBox
                            sx={{
                              paddingLeft: { mini: "16px", sm: "2rem" },
                              paddingRight: { mini: "16px", sm: "2rem" },
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <SoftTypography
                              sx={{
                                textFillColor: "primary.main", 
                                color: "#000000",
                                fontSize: { mini: "14px", md: '24px', sm: "24px", },
                                lineHeight: { mini: "18px", md: '24px', sm: "24px" },
                                fontWeight: { mini: 500, sm: 600 }
                              }}
                            > 
                              Team Performance VS. The Yeti
                            </SoftTypography>
                            <SoftTypography
                              onClick={() => setOpenDailyTargetDialog(true)}
                              sx={{
                                color: "primary.main",
                                fontSize: '12px',
                                fontWeight: '600',
                                cursor: 'pointer'
                              }}>
                              View Daily Target
                            </SoftTypography>
                          <Dialog
                            open={openDailyTargetDialog}
                            onClose={() => setOpenDailyTargetDialog(false)}
                            sx={{
                              ".MuiPaper-root": {
                                borderRadius: "8px",
                                background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
                                boxShadow: 24,
                                padding: '1.75rem',
                                maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
                                width: "100%",
                                overflow: "visible",
                                backdropFilter: 'blur(14px)'
                              },
                            }}
                          >
                            <SoftBox display="flex" alignItems="center" sx={{ padding: "0 !important" }}>
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox display="flex" flexDirection="column">
                                  <SoftTypography
                                    component="h3"
                                    sx={{
                                      fontSize: "18px",
                                      color: "#FFF",
                                      margin: 0,
                                      padding: 0,
                                      fontWeight: "500",
                                    }}
                                  >
                                    My today’s Goal
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                              <div className="ml-auto">
                                <SoftButton
                                  variant="outlined"
                                  color="white"
                                  sx={{
                                    "& svg": { fontSize: "1rem !important" },
                                    borderRadius: "50%",
                                    minWidth: "max-content",
                                    minHeight: "max-content",
                                    padding: "0.25rem",
                                  }}
                                  onClick={() => setOpenDailyTargetDialog(false)}
                                >
                                  <CloseIcon />
                                </SoftButton>
                              </div>
                            </SoftBox>
                            <Divider
                                sx={{
                                  backgroundColor: "#D5EBFF",
                                }}
                              />
                            <DialogContent sx={{ padding: "0 !important" }}>
                              {groupKpis?.map((kpi) => (
                                <SoftBox key={kpi._id} sx={{
                                  background: '#2A292A',
                                  padding: '16px',
                                  marginBottom: '12px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '12px'
                                }}>
                                  <SoftBox sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                    <SoftTypography sx={{
                                      color: '#D0D5DD',
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      lineHeight: '20px',
                                      textDecoration: 'underline'
                                    }}>
                                      {kpi?.title || 'Unknown'}
                                    </SoftTypography>
                                    <SoftTypography sx={{
                                      color: '#F9FAFB',
                                      fontSize: '20px', 
                                      fontWeight: 500,
                                      lineHeight: '24px'
                                    }}>
                                      Target: {formatNumber(kpi.target, kpi.kind)}
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              ))}
                            </DialogContent>
                            <DialogActions sx={{ padding: "0 !important", mt: 2 }}>
                              <SoftButton
                                onClick={() => setOpenDailyTargetDialog(false)}
                                sx={{
                                  background: '#FFFFFF',
                                  border: '1px solid #D0D5DD',
                                  borderRadius: '3px',
                                  color: '#344054',
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  padding: '5px 40px!important',
                                  '&:hover': {
                                    background: '#F9FAFB'
                                  }
                                }}
                              >
                                Close
                              </SoftButton>
                            </DialogActions>
                          </Dialog>
                          </SoftBox>
                          <hr className="my-3 divide-slate-300"/>
                          <Box
                            sx={{
                              marginX: "auto",
                              width: "100%",
                              paddingX: { mini: "0.5rem", md: "1rem", lg: "1rem", xl: "2rem" },
                              paddingTop: { mini: "0.5rem", md: "0rem", lg: "1rem" },
                              flex: 1,
                              display: "grid",
                              alignItems: "start",
                              position: "relative",
                            }}
                            >
                            <SoftBox sx={{ padding: { mini: "0 16px 0 16px", lg: "unset" } }}>
                              <YetiKpiBox kpis={kpis} />
                            </SoftBox>
                          </Box>
                        </SoftBox>
                      </SoftBox>
                    </CustomWidthTooltip>
                  </Grid>
                  <Grid item mini={12}>
                    <LeaderBoard isRealAdmin={isRealAdmin} />
                  </Grid>
                </>
              ) : (
                isAdmin && (
                  <Grid item mini={12} height="10rem">
                    <AddKpiBox />
                  </Grid>
                )
              )}
            </Grid>
            <Box>
              <Footer />
            </Box>
          </Box>
          <Modal open={openModal}>
            <SoftBox sx={style}>
              <SoftBox sx={{textAlign: "center"}}>
                <img src={onBoardingImage} alt="onboarding"/>
                <SoftTypography sx={{color: "#344054", fontWeight: "bold", fontSize: "20px", lineHeight: "24px", marginTop: "2rem"}} variant="h1" component="h1">
                  Welcome to Your GameMyBiz Dashboard!
                </SoftTypography>
                <SoftTypography sx={{color: "#667085", fontWeight: "400", fontSize: "16px", lineHeight: "24px", marginTop: "1rem"}} variant="p" component="p">
                  Get ready to level up your productivity and engagement.<br />
                  Would you like to take a quick tour to learn more?
                </SoftTypography>
                <SoftButton onClick={handleCloseModal} 
                    sx={{
                      background: "#F9FAFB", 
                      color: "#475467", 
                      borderRadius: "8px", 
                      marginTop: "2rem",
                      border: "1px solid #EAECF0",
                      boxShadow: "none !important",
                      padding: "16px 32px"
                      }}>
                  Take a quick Product Tour
                </SoftButton>
                <SoftTypography sx={{color: "#667085", fontWeight: "400", fontSize: "16px", lineHeight: "24px", marginTop: "1rem", cursor: "pointer"}} variant="p" component="p" onClick={handleCloseModal2}>
                  Skip Tour and Explore on Your Own
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Modal>
        </SoftBox>
      )}
    </SoftBox>
  );
}

export default Homepage;