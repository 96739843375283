import React, { useState } from "react";
import UmTable from "./UmTable";
import SoftBox from "../../components/SoftBox";
// import InfoBar from "../../components/InfoBar";
import SoftTypography from "../../components/SoftTypography";
import PageHeader from "../../components/PageHeader";
import { Grid } from "@mui/material";
import SoftButton from "../../components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";


// Imported Styles
import "./userManagementStyles.css";

const UserManagement = () => {
    const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <PageHeader
        headerText="Players"
        subheaderText="Manage players in the workspace"
      />
      {isVisible && 
        <SoftBox className={alert} sx={{padding: { mini: "0.5rem", xxs: "1.5rem 1.5rem 0rem 1.5rem",marginTop: "-2rem" }}}>
          <Grid container sx={{padding: "20px", background: "linear-gradient(273.63deg, #7928CA 0%, #FF0080 100%)", alignItems: "center", display: "flex", borderRadius: "8px" }}>
            <Grid item mini={10}>
                <SoftTypography sx={{ color: "#FFFFFF", fontSize: { mini: "18px", md: "16px" }, lineHeight: "24px", fontWeight: "500" }} variant="h3">
                  Welcome to Player Management. Add or remove players, This is a friendly reminder that any extra players will be charged $5 each in the upcoming billing cycle
                </SoftTypography>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} mini={2}>
              <SoftButton
                variant="outlined"
                color="white"
                onClick={() => setIsVisible(false)}
                sx={{
                  "& svg": { fontSize: "1.25rem !important" },
                  borderRadius: "50%",
                  minWidth: "max-content",
                  minHeight: "max-content",
                  padding: "0.25rem",
                }}
              >
                <CloseIcon />
              </SoftButton>
            </Grid>
          </Grid>
          </SoftBox>
        }
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <Grid container>
          <Grid item mini={12}>
            <UmTable />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default UserManagement;
