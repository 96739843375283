import React, { useEffect, useState } from "react";

import { UseGroupKpis, UseMyKpis } from "../../../hooks/kpi";
import { formatNumber } from "../../../helpers/formatter";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";
import WidgetBox from "../WidgetBox";

const TopKPI = ({ widget = {}, reFetch, setReFetch, showPerformBox, showCharts = false, isSmallScreen = false }) => {
  const [kpis, setKpis] = useState([]);

  const groupKpis = UseGroupKpis();
  const userKpis = UseMyKpis();

  useEffect(() => {
    if (groupKpis) {
      const kpiSummary = groupKpis?.map((kpiItem) => ({
        ...kpiItem,
        amount: userKpis?.month[kpiItem._id]?.amt ?? 0,
        points: userKpis?.month[kpiItem._id]?.pts ?? 0,
        target: kpiItem.target,
      }));
      setKpis(kpiSummary);
    }
  }, [groupKpis, userKpis]);

  return showPerformBox ? (
    <PerformBoxFlexible
      isTopKPI={true}
      title={kpis[0]?.title || ""}
      amount={formatNumber(kpis[0]?.amount || 0, kpis[0]?.kind) || 0}
      imgKey={kpis[0]?.title || 0}
      showCharts={showCharts}
      isSmallScreen={isSmallScreen}
    />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={kpis[0]?.title || ""}
      widgetMetrics={formatNumber(kpis[0]?.amount || 0, kpis[0]?.kind) || 0}
      reFetch={reFetch}
      setReFetch={setReFetch}
    />
  );
};

export default TopKPI;
