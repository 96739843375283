import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { UseSelectedWorkspace } from "../../hooks/company";
import { UseViewAsUser } from "../../hooks/user";
import { UseKpiLoading } from "../../hooks/kpi";

import Diversity2Icon from "@mui/icons-material/Diversity2";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PsychologyIcon from "@mui/icons-material/Psychology";
import toast from "react-hot-toast";

const Blackout = () => {
  const [open, setOpen] = useState(true);
  const [fade, setFade] = useState(false);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const workspace = UseSelectedWorkspace();
  const loaded = UseKpiLoading();
  const viewAsUser = UseViewAsUser();
  const [playerView, setPlayerView] = useState(viewAsUser);

  const toastMessage = (elem) => {
    toast(elem, {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
        alignItems: "center",
      },
      position: "bottom-center",
    });
  };

  useEffect(() => {
    // Trigger fade in when transitioning from one workspace to another
    setCurrentWorkspace(workspace?.name);
    if (currentWorkspace && workspace?.name !== currentWorkspace) {
      setOpen(true);
      setFade(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  useEffect(() => {
    // Trigger fade out when KPIs are loaded (having the KPI scores loaded usually mean that the heavy fetching is done)
    if (!loaded && fade) {
      setFade(false);
      toastMessage(
        <>
          You are now on {currentWorkspace} {workspace?.parentId ? 'Sub-Workspace' : 'Workspace'}
          <Diversity2Icon
            sx={{ ml: 0.5, mr: 0.5, textAlign: "center" }}
            fontSize="large"
            color="inherit"
          />
        </>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    // Trigger fade in when transitioning from Admin view to Player view, vice versa
    if (playerView !== viewAsUser) {
      setFade(true);
      setTimeout(() => {
        setFade(false);
        toastMessage(
          <>
            You are now interacting as:
            {viewAsUser ? (
              <SportsEsportsIcon
                sx={{ ml: 0.5, mr: 0.5 }}
                fontSize="large"
                color="inherit"
              />
            ) : (
              <PsychologyIcon
                sx={{ ml: 0.5, mr: 0.5 }}
                fontSize="large"
                color="inherit"
              />
            )}
            {viewAsUser ? "Player" : "Admin"}
          </>,
        );
      }, 250);
      setPlayerView(viewAsUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAsUser]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          appear={true}
          in={fade}
          timeout={500}
        >
          <CircularProgress color="inherit" size="5rem" />
        </Backdrop>
      )}
    </>
};

export default Blackout;
