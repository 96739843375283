import React, { useState } from "react";
import { Dialog, Grid } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import ActivateModal from "./components/SetupModal/ActivateModal";
import RankModal from "./components/SetupModal/Rank";
import LevelModal from "./components/SetupModal/Level";
import TopKPIModal from "./components/SetupModal/TopKPI";
import PersonalGoalsModal from "./components/SetupModal/PersonalGoals";
import AdminBulletin from "./components/SetupModal/AdminBulletin";
import CompanyValues from "./components/SetupModal/CompanyValues";
import PlayerMemo from "./components/SetupModal/PlayerMemo";
import QuoteOfTheDay from "./components/SetupModal/QuoteOfTheDay";
import QuoteOfTheDayEdit from "./components/EditModal/QuoteOfTheDayEdit";
import PersonalGoalEdit from "./components/EditModal/PersonalGoalEdit";
import CompanyValuesEdit from "./components/EditModal/CompanyValuesEdit";
import AdminBulletinEdit from "./components/EditModal/AdminBulletinEdit";
import EditModal from "./components/EditModal/EditModal";
import { refetchUserData } from "../../redux/auth/actions";
import {
  UseCompanyId,
  UseIsAdmin,
  UseUserDetails,
  UseUserId,
} from "../../hooks/auth";
import {
  DELETE_WIDGET_FROM_LIST,
  TOGGLE_OFF_WIDGET,
  TOGGLE_ON_WIDGET,
} from "../../api/userWidgets";
import boxShadow from "../../assets/theme/functions/boxShadow";
import { textAlign } from "@mui/system";

import topKPI from "../../img/widgets/topkpi.png";
import adminBulletin from "../../img/widgets/admin.png";
import companyValues from "../../img/widgets/company.png";
import level from "../../img/widgets/level.png";
import personalGoals from "../../img/widgets/personalgoals.png";
import playersMemo from "../../img/widgets/playermemo.png";
import ranking from "../../img/widgets/ranking.png";

export const Components = {
  ranking: RankModal,
  level: LevelModal,
  top_kpi: TopKPIModal,
  personal_goals: PersonalGoalsModal,
  admin_bulletin: AdminBulletin,
  company_values: CompanyValues,
  player_memo: PlayerMemo,
  quote_of_the_day: QuoteOfTheDay,
};

export const EditComponents = {
  personal_goals: PersonalGoalEdit,
  admin_bulletin: AdminBulletinEdit,
  company_values: CompanyValuesEdit,
  player_memo: PlayerMemo,
  quote_of_the_day: QuoteOfTheDayEdit,
};

const widgetsByAdmin = {
  admin_bulletin: "admin_bulletin",
  company_values: "company_values",
  player_memo: "player_memo",
  quote_of_the_day: "quote_of_the_day",
};

const WidgetBox = ({ widget, setReFetch, widgetTitle, widgetMetrics }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(
    widget.toggled ? "Deactivate" : "Activate",
  );

  const widgetIcon = {
    'Admin Bulletin': adminBulletin,
    'Company Values': companyValues,
    'Level': level,
    'Personal Goals': personalGoals,
    'Player Memo': playersMemo,
    'Ranking': ranking,
    'Top KPI': topKPI
  }[widget.title];

  const open = Boolean(anchorEl);
  const Component = Components[widget.key];
  const EditComponent = EditComponents[widget.key];

  const userDetails = UseUserDetails();
  const userId = UseUserId();
  const isAdmin = UseIsAdmin();
  const companyId = UseCompanyId();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  const openEditModal = () => setEditModal(true);
  const closeEditModal = () => setEditModal(false);

  const triggerHandler = async () => {
    // Toggled means - Widget already activated
    if (widget.toggled || buttonLabel !== "Activate") {
      // Remove widget
      const widgetToRemove = userDetails.widgets.find(
        (item) => item.key === widget.key,
      );

      try {
        if (widgetsByAdmin[widget.key] && !isAdmin && !userDetails.isAdmin) {
          await TOGGLE_OFF_WIDGET(widgetToRemove._id, userId);
        } else {
          await DELETE_WIDGET_FROM_LIST(widgetToRemove._id, userId);
        }

        dispatch(refetchUserData(userId));
        setTimeout(() => {
          setButtonLabel("Activate");
          toast.success("Widget deactivated successfully");
          setReFetch();
        }, 1000);
      } catch (error) {
        console.log("Error while deactivating the widget", error);
      }
    } else {
      // Alow user to activate the widget

      // Check the widget key, and see if it was setup by the company admin
      if (widgetsByAdmin[widget.key] && !isAdmin && !userDetails.isAdmin) {
        try {
          const payload = {
            userId,
            companyId,
            key: widget.key,
          };

          // TOGGLE_ON_WIDGET will see if the widget is activated, if yes than the widget will be added to the users list of widgets
          await TOGGLE_ON_WIDGET(payload);
          dispatch(refetchUserData(userId));
          setTimeout(() => {
            setButtonLabel("Deactivate");
            toast.success("Widget activated successfully");
            setReFetch();
          }, 1000);
        } catch (error) {
          console.log("Error while calling TOGGLE_ON_WIDGET", error.response);
          toast.error(
            error.response?.data?.error?.message || "Something went wrong!",
          );
        }
        return;
      }
      openModal(true);
    }
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={closeModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <ActivateModal closeModal={closeModal}>
          <Component
            widget={widget}
            closeModal={closeModal}
            setButtonLabel={setButtonLabel}
            setReFetch={setReFetch}
          />
        </ActivateModal>
      </Dialog>
      <Dialog
        open={editModal}
        onClose={closeEditModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <EditModal closeModal={closeEditModal}>
          <EditComponent
            widget={widget}
            closeModal={closeEditModal}
            setButtonLabel={setButtonLabel}
          />
        </EditModal>
      </Dialog>
        <SoftBox
          sx={{
            borderRadius: "16px",
            background: "#F3F7FA",
            marginBottom: "20px !important",
            backdropFilter: "blur(15px)",
            backgroundBlendMode: "overlay, normal",
            border: "1px solid #EAECF0",
          }}
        >
        <SoftBox container style={{padding: "14px"}}>
          <SoftBox
            sx={{
              borderRadius: "16px",
              backdropFilter: "blur(15px)",
              backgroundBlendMode: "overlay, normal",
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0.39%, rgba(255, 255, 255, 0) 100.39%), radial-gradient(77.42% 100% at 50% 0.39%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.09) 17.75%), linear-gradient(0deg, #FFFFFF, #FFFFFF);`,
              padding: 1,
              minHeight: "78px",
            }}
          >
            <SoftBox sx={{ paddingLeft: "10px" }}>
              <SoftTypography variant="body2">{widget.title}</SoftTypography>
              <SoftTypography variant="h4" sx={{
                fontSize: widget.key === 'company_values' || widget.key === 'personal_goals' || widget.key === 'admin_bulletin' ? '20px' : undefined,
                bottom: widget.key === 'company_values' || widget.key === 'personal_goals' || widget.key === 'admin_bulletin' ? '0' : undefined,
                color: '#343A40',
                fontWeight: 500
              }}>
                {widgetMetrics}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          sx={{
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
            backdropFilter: "blur(15px)",
            backgroundBlendMode: "overlay, normal",
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0.39%, rgba(255, 255, 255, 0) 100.39%), radial-gradient(77.42% 100% at 50% 0.39%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.09) 17.75%), linear-gradient(0deg, #FFFFFF, #FFFFFF);`,
          }}
        >
          <Grid container style={{padding: "14px"}}>
            <Grid item mini={2}>
               <img src={widgetIcon} alt={widgetTitle} style={{marginTop: "0.5rem"}} />
            </Grid>
            <Grid item mini={10}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <SoftBox display="flex" flexDirection="column" sx={{minHeight: "80px", paddingLeft: "1rem"}}>
                  <SoftTypography
                    sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}
                  >
                    {widgetTitle}
                  </SoftTypography>
                  <SoftTypography sx={{ fontSize: "12px", color: "#6C757D", marginBottom: !widget?.description ? "2rem" : 0 }}>
                    {widget?.description}
                  </SoftTypography>
                </SoftBox>
                {widget.editable && buttonLabel !== "Activate" && widgetTitle !== "Quote of the Day" && widgetTitle !== "Quote of the Day" && (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    disabled={(widgetTitle === 'Player Memo' || widgetTitle === 'Admin Bulletin') && !isAdmin}
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={openEditModal}>
                    <SettingsIcon fontSize="small" mr={2} />
                    Edit Widget
                  </MenuItem>
                </Menu>
              </SoftBox>
            </Grid>
          </Grid>
          <SoftBox
            display="flex"
            justifyContent="center"
            pb={2}
            alignItems="center"
          >
            <SoftButton
              color="primary"
              size="small"
              disabled={(widgetTitle === 'Admin Bulletin') && !isAdmin}
              sx={{
                textAlign: 'center',
                background: "#FFFFFF",
                border: "1px solid #EAECF0;",
                borderRadius: "4px",
                textTransform: "capitalize",
                color: "#1D2939",
              }}
              onClick={triggerHandler}
            >
              {buttonLabel}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
};

export default WidgetBox;
