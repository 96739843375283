import React, { useEffect, useRef, useState } from "react";
import { Card, Dialog, IconButton, Menu, MenuItem } from "@mui/material";
import { Line, Bar } from "react-chartjs-2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";
import configs from "./configs";
import ExpandableView from "./ExpandableView";
import Grid from "@mui/material/Grid";
import PersonalGoalEdit from "../../pages/Widgets/components/EditModal/PersonalGoalEdit";
import AdminBulletinEdit from "../../pages/Widgets/components/EditModal/AdminBulletinEdit";
import EditModal from "../../pages/Widgets/components/EditModal/EditModal";

import topKPI from "../../img/widgets/top_kpi.png";
import adminBulletin from "../../img/widgets/admin_bulletin.png";
import companyValues from "../../img/widgets/company_values.png";
import level from "../../img/widgets/level_widgets.png";
import personalGoals from "../../img/widgets/personal_goals.png";
import playersMemo from "../../img/widgets/players_memo.png";
import ranking from "../../img/widgets/ranking_widgets.png";

import { UseIsAdmin } from "../../hooks/auth";


function PerformBox({ isTopKPI, widget, title, amount, showGraph = true }) {

  const widgetIcon = isTopKPI ? topKPI : {
    'Admin Bulletin': adminBulletin,
    'Company Values': companyValues,
    'Level': level,
    'Personal Goals': personalGoals,
    'Player Memo': playersMemo,
    'Ranking': ranking
  }[title];

  const chartRef = useRef(null);
  const isAdmin = UseIsAdmin();
  const [chartData, setChartData] = useState({});
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, options } = chartData;

  const closeModal = () => {
    setModal(false);
  };

  const openEditModal = () => setEditModal(true);
  const closeEditModal = () => setEditModal(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const kpiChartData = {
      labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: title,
          color: "dark",
          data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
        },
      ],
    };

    const chartDatasets = kpiChartData.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: "#ffffff",
      fill: false,
      maxBarThickness: 6,
    }));

    setChartData(configs(kpiChartData.labels, chartDatasets));
  }, [title]);

  return (
    <>
      <Dialog
        open={modal}
        onClose={closeModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <ExpandableView title={title} amount={amount} closeModal={closeModal} />
      </Dialog>
      <Dialog
        open={editModal}
        onClose={closeEditModal}
        sx={{
          ".MuiPaper-root": {
            padding: { mini: "1rem", md: "1rem 1.5rem" },
            borderRadius: "8px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(25px)",
            width: "100%",
            maxWidth: "600px",
            borderWidth: "1px",
            borderColor: "#FFF",
          },
        }}
      >
        <EditModal closeModal={closeEditModal}>
          {(() => {
            if (title === 'Personal Goals') {
              return (
                <PersonalGoalEdit
                  widget={widget}
                  closeModal={closeEditModal}
                />
              );
            }
            if (title === 'Admin Bulletin') {
              return (
                <AdminBulletinEdit
                  widget={widget}
                  closeModal={closeEditModal}
                />
              );
            }
            return null;
          })()}
        </EditModal>
      </Dialog>
      <Card
        onClick={() => title === 'Personal Goals' || (isAdmin && title === 'Admin Bulletin') ? openEditModal() : setModal(true)}
        sx={{
          marginTop: "1rem",
          boxShadow: "0px 0px 0px 0px",
          background: {
            mini: "linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
            md: "#FCFCFD",
          },
          height: "7rem",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <SoftBox lineHeight={1} sx={{ padding: { mini: 1, xxs: 2 } }}>
          <Grid container>
            <Grid item md={6} lg={3} style={{ textAlign: 'center' }}>
              <SoftBox sx={{ background: '#FFFFFF', padding: "25%", border: '2px solid #F2F4F7', borderRadius: '16px' }}>
                <img src={widgetIcon} alt={title} />
              </SoftBox>
            </Grid>
            <Grid item md={6} lg={9} sx={{ paddingLeft: '1rem', display: 'flex', alignItems: 'center' }}>
              <SoftBox>
                <SoftTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="medium"
                  sx={{
                    color: "#98A2B3",
                    fontSize: "16px",
                  }}
                >
                  {title}
                </SoftTypography>
                <SoftTypography
                  variant="h1"
                  fontWeight="regular"
                  sx={{
                    color: "primary.main",
                    whiteSpace: "nowrap",
                    width: { mini: "110px", md: "290px" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                    fontWeight: '500'
                  }}
                >
                  {amount}&nbsp;
                </SoftTypography>
              </SoftBox>
                
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </>
  );
}

export default PerformBox;
