/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { UseIsAdmin, UseUserDetails } from "../../hooks/auth";
import { Dialog, DialogContent } from "@mui/material";

import ItemBox from "./ItemBox";
// import AddKpiBox from "./AddKpiBox";
import PopUpModal from "./PopUp";
import ConfettiFireworks from "../ParticleEffects/ConfettiFireworks";
import Grid from "@mui/material/Grid";
import SoftBox from "../SoftBox";
import { useNavigate } from "react-router-dom";
import { GET_COMPANY_USERS } from "../../api/user";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";

import { UseSelectedWorkspace } from "../../hooks/company";

const YetiKpiBox = ({ kpis }) => {
  const navigate = useNavigate();
  const [fxComponent, setFxComponent] = React.useState(null);
  const isAdmin = UseIsAdmin();
  const user = UseUserDetails();
  const workspace = UseSelectedWorkspace();

  const popConfetti = (kpi) => {
    if (kpi.direction > 0) {
      setFxComponent(<ConfettiFireworks count={5} />);
    }
  };

  const [openMetricDialog, setOpenMetricDialog] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      if (!user?.companyId) return;
      const response = await GET_COMPANY_USERS(user.companyId);
      if (response?.data) {
        const filteredUsers = response.data.filter(u => u.groupId && u.groupId === workspace._id);
        setUsers(filteredUsers.length);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [user?.companyId, workspace._id]);

  return (
    <Grid
      container
      sx={{
        height: "100%",
        width: "100%",
        rowGap: 2,
        "& > div:nth-child(odd)": {
          paddingRight: { mini: 0, md: 1 },
        },
        "& > div:nth-child(even)": {
          paddingLeft: { mini: 0, md: 1 },
        },
      }}
    >
      {kpis.map((kpi) => (
        <Grid
          key={kpi._id}
          item
          xs={12}
          md={6}
          sx={{
            flexBasis: "100%",
            maxHeight: "50%",
            maxWidth: "100%",
          }}
        >
        {users < 1 && isAdmin ?  (
          <ItemBox kpi={kpi} amount={kpi.amount} points={kpi.points} 
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenMetricDialog(true)
            }} 
          />
        ) : (
          <PopUpModal
            kpi={kpi}
            onSave={()=>popConfetti(kpi)}
            onOpen={() => setFxComponent(null)}
          >
            <ItemBox kpi={kpi} amount={kpi.amount} points={kpi.points} />
          </PopUpModal>
        )}
        </Grid>
      ))}
      <Dialog
        open={openMetricDialog}
        onClose={() => setOpenMetricDialog(false)}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "8px",
            background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
            boxShadow: 24,
            padding: '1.75rem',
            maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
            width: "100%",
            overflow: "visible",
            backdropFilter: 'blur(14px)'
          },
        }}
      >
        <DialogContent sx={{ padding: "0 !important" }}>
          <SoftBox display="flex" justifyContent="center" alignItems="center">
            <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_175_5395)" fillOpacity="0.2" stroke="url(#paint1_linear_175_5395)" strokeWidth="0.438272"/>
              <circle cx="35.5001" cy="35.5001" r="28.7068" fill="url(#paint2_linear_175_5395)" fillOpacity="0.5" stroke="url(#paint3_linear_175_5395)" strokeWidth="0.438272"/>
              <path d="M36.0001 28.333C36.2653 28.333 36.5197 28.4384 36.7072 28.6259C36.8947 28.8134 37.0001 29.0678 37.0001 29.333V35.333C37.0001 35.5982 36.8947 35.8526 36.7072 36.0401C36.5197 36.2276 36.2653 36.333 36.0001 36.333C35.7349 36.333 35.4805 36.2276 35.293 36.0401C35.1054 35.8526 35.0001 35.5982 35.0001 35.333V29.333C35.0001 29.0678 35.1054 28.8134 35.293 28.6259C35.4805 28.4384 35.7349 28.333 36.0001 28.333ZM36.0001 41.6663C36.3537 41.6663 36.6928 41.5259 36.9429 41.2758C37.1929 41.0258 37.3334 40.6866 37.3334 40.333C37.3334 39.9794 37.1929 39.6402 36.9429 39.3902C36.6928 39.1401 36.3537 38.9997 36.0001 38.9997C35.6465 38.9997 35.3073 39.1401 35.0573 39.3902C34.8072 39.6402 34.6667 39.9794 34.6667 40.333C34.6667 40.6866 34.8072 41.0258 35.0573 41.2758C35.3073 41.5259 35.6465 41.6663 36.0001 41.6663Z" fill="#981B14"/>
              <path d="M29.7707 20.9603C29.8635 20.8674 29.9736 20.7936 30.0949 20.7433C30.2161 20.693 30.3461 20.667 30.4774 20.667H41.5227C41.7881 20.667 42.0414 20.7723 42.2294 20.9603L50.0401 28.771C50.2281 28.9577 50.3334 29.211 50.3334 29.4777V40.523C50.3334 40.6543 50.3074 40.7843 50.2571 40.9055C50.2068 41.0268 50.133 41.1369 50.0401 41.2297L42.2294 49.0403C42.1367 49.1333 42.0265 49.207 41.9053 49.2574C41.784 49.3077 41.654 49.3336 41.5227 49.3337H30.4774C30.3461 49.3336 30.2161 49.3077 30.0949 49.2574C29.9736 49.207 29.8635 49.1333 29.7707 49.0403L21.9601 41.2297C21.8671 41.1369 21.7934 41.0268 21.7431 40.9055C21.6927 40.7843 21.6668 40.6543 21.6667 40.523V29.4777C21.6667 29.2123 21.7721 28.959 21.9601 28.771L29.7707 20.9603ZM30.8907 22.667L23.6667 29.8923V40.1083L30.8921 47.3337H41.1081L48.3334 40.1097V29.891L41.1094 22.667H30.8907Z" fill="#981B14"/>
              <defs>
              <linearGradient id="paint0_linear_175_5395" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
              <stop stopColor="#9E2929" stopOpacity="0"/>
              <stop offset="0.300199" stopColor="#9E2929" stopOpacity="0.06"/>
              <stop offset="1" stopColor="#9E2929"/>
              </linearGradient>
              <linearGradient id="paint1_linear_175_5395" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
              <stop stopColor="#98A2B3" stopOpacity="0.03"/>
              <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
              </linearGradient>
              <linearGradient id="paint2_linear_175_5395" x1="35.5001" y1="6.57422" x2="36.6752" y2="98.3835" gradientUnits="userSpaceOnUse">
              <stop stopColor="#9E2929" stopOpacity="0"/>
              <stop offset="0.356773" stopColor="#9E2929" stopOpacity="0.2"/>
              <stop offset="1" stopColor="#9E2929" stopOpacity="0.7"/>
              </linearGradient>
              <linearGradient id="paint3_linear_175_5395" x1="35.5001" y1="6.57422" x2="35.5001" y2="64.4261" gradientUnits="userSpaceOnUse">
              <stop stopColor="#475467" stopOpacity="0.44"/>
              <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
              </linearGradient>
              </defs>
            </svg>
          </SoftBox>
          <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '24px', fontWeight: '400', marginTop: '1rem', marginBottom: '4rem'}} variant="p" component="p">
            No players are set up in this workspace.<br/>
            Add one to start adding scores. 
          </SoftTypography>
          <SoftBox display="flex" justifyContent="center" alignItems="center" sx={{textAlign: 'center'}}>
            <SoftButton sx={{width: '40%', marginTop: '1.5rem', background: '#F9FAFB!important', color: '#667085!important'}} onClick={() => setOpenMetricDialog(false)}>
              Okay
            </SoftButton>
          </SoftBox>
        </DialogContent>
      </Dialog>
      {isAdmin && (
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          flexBasis: "100%",
          maxHeight: "50%",
          maxWidth: "100%",
        }}
      >
        <SoftBox
        sx={{
          width: "100%",
          height: "100%", 
          position: "relative",
          borderRadius: "1vw",
          background: "#FCFCFD",
          textAlign: "center",
          border: "2px dotted #EAECF0",
          cursor: kpis.length === 5 ? "not-allowed" : "pointer",
          opacity: kpis.length === 5 ? 0.5 : 1
        }}
        onClick={() => {
          if (kpis.length < 5) {
            navigate("/gameboards/workspace-kpi/custom-metric-types")
          }
        }}
        >
          <SoftBox
            lineHeight={1}
            className="h-full"
            sx={{ px: "8%", py: "5%" }}
          >
          <svg className="mt-2 text-center mx-auto" width="71" height="28" viewBox="0 0 71 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_dd_4643_3702)">
          <g filter="url(#filter1_dd_4643_3702)">
          <path d="M57.3793 2H13.6207C12.7256 2 12 2.72561 12 3.62069V11.7241C12 12.6192 12.7256 13.3448 13.6207 13.3448H57.3793C58.2744 13.3448 59 12.6192 59 11.7241V3.62069C59 2.72561 58.2744 2 57.3793 2Z" fill="white"/>
          </g>
          <path d="M34.3658 4.5918H25.9382C25.4012 4.5918 24.9658 5.02716 24.9658 5.56421C24.9658 6.10126 25.4012 6.53662 25.9382 6.53662H34.3658C34.9029 6.53662 35.3382 6.10126 35.3382 5.56421C35.3382 5.02716 34.9029 4.5918 34.3658 4.5918Z" fill="url(#paint0_linear_4643_3702)"/>
          <path d="M40.2001 8.80566H25.938C25.4009 8.80566 24.9656 9.24103 24.9656 9.77808C24.9656 10.3151 25.4009 10.7505 25.938 10.7505H40.2001C40.7371 10.7505 41.1725 10.3151 41.1725 9.77808C41.1725 9.24103 40.7371 8.80566 40.2001 8.80566Z" fill="url(#paint1_linear_4643_3702)" fillOpacity="0.1"/>
          </g>
          <defs>
          <filter id="filter0_dd_4643_3702" x="0" y="2" width="71" height="35.3447" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_4643_3702"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4643_3702"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_4643_3702"/>
          <feOffset dy="12"/>
          <feGaussianBlur stdDeviation="8"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_4643_3702" result="effect2_dropShadow_4643_3702"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4643_3702" result="shape"/>
          </filter>
          <filter id="filter1_dd_4643_3702" x="10.4103" y="0.94021" width="50.1794" height="14.5241" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.529895"/>
          <feGaussianBlur stdDeviation="0.529895"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4643_3702"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="0.529895"/>
          <feGaussianBlur stdDeviation="0.794843"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_4643_3702" result="effect2_dropShadow_4643_3702"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4643_3702" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_4643_3702" x1="33.3884" y1="12.3033" x2="23.49" y2="8.95643" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7928CA"/>
          <stop offset="1" stopColor="#FF0080"/>
          </linearGradient>
          <linearGradient id="paint1_linear_4643_3702" x1="38.1258" y1="16.5172" x2="24.6522" y2="9.39879" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7928CA"/>
          <stop offset="1" stopColor="#FF0080"/>
          </linearGradient>
          </defs>
          </svg>
          <h5 style={{
              fontSize: "14px", 
              fontWeight: "700",
              lineHeight: "18px",
              }}>{kpis.length >= 5 ? "All Metrics Added" : "Add Metrics"}</h5>
            <p style={{fontSize: "11.32px",lineHeight: "14px",}}>
              {kpis.length >= 5 ? (
                "Congratulations! You've used up all your metrics!"
              ) : (
                `You have (${5 - kpis.length}) more Metrics`
              )}
            </p>
          </SoftBox>
        </SoftBox>
      </Grid>
      )}
      {fxComponent}
    </Grid>
  );
};

export default YetiKpiBox;
